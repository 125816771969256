import { axiosPublic, axiosServerPublicWithPort } from '../../ApiHandler'

/**
 * Provides the list of all datastore stats.
 * @returns -- list of all datastore stats.
 */
export const fetchCountries = () => {
  return axiosPublic.get(`/api/v1/countries`)
}

/**
 * Provides the list of all issues stats.
 * @returns -- list of all issues stats.
 */
export const fetchCountryStates = (params) => {
  return axiosPublic.post(`/api/v1/countries/state`, params)
}

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchCountryStateCities = (params) => {
  return axiosPublic.post(`/api/v1/countries/state/cities`, params)
}

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const fetchCities = () => {
  return axiosPublic.get(`/api/v1/cities`)
}

export const fetchAttractionFromLocation = (params) => {
  return axiosPublic.post(`/api/v1/attractions/location`, params)
}

export const fetchAttractionDetails = (params) => {
  return axiosPublic.get(`/api/v1/attractions?_id=${params.id}`)
}

export const fetchAttractionReviews = (params) => {
  return axiosPublic.post(`/api/v1/reviews`, params)
}

export const createReview = (params) => {
  // return axiosPublic.post(`/api/v1/create_reviews`, params);

  axiosPublic({
    method: 'post',
    url: `/api/v1/create_reviews`,
    data: params,
    headers: { 'Content-Type': 'multipart/form-data' },
  })
}

/**
 * Provides the list of testimonials.
 * @returns -- list of all testimonials.
 */
export const fetchTestimonials = (params) => {
  return axiosPublic.get(`/api/v1/testimonial/get/`)
}

export const createTestimonial = (params) => {
  return axiosServerPublicWithPort({
    method: 'post',
    url: `/api/v1/testimonial/post/`,
    data: params,
    // headers: { 'Content-Type': 'multipart/form-data' },
    headers: {
      Accept: 'application/json, text/plain, /',
      'Content-Type': 'multipart/form-data',
    },
  })
}
