import { useState, useEffect } from 'react'
import ReactStars from 'react-rating-stars-component'
import Select from 'react-select'
import './LandingComponent.scss'

import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import OwlCarousel from 'react-owl-carousel'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'

import dalLake from '../../assets/dal-lake.jpeg'
import mughalGardens from '../../assets/mughal-gardens.jpeg'
import shalimar from '../../assets/shalimar-bagh.jpeg'

import img from '../../assets/img/tour-destanation/d-1.png'
import { useDispatch, useSelector } from 'react-redux'
import {
  listAttractionFromLocation,
  listCountries,
  listCountryStateCities,
  listCountryStates,
} from '../../shared/redux/slices/dashboardSlice'
import BlockUi from 'react-block-ui'
import { loginSlice } from '../../shared/redux/slices/authSlice'
import { getToken, setContext } from '../../shared/SessionHelper'

const LandingComponent = (props) => {
  const slides = [
    {
      src: dalLake,
      altText: 'Dal Lake',
      caption1: 'Discover Amazing Places of the world',
      caption2: 'Discover Amazing Places of the world',
      url: '#',
    },
    {
      src: mughalGardens,
      altText: 'Mughal Gardens',
      caption1: 'Discover Amazing Places of the world',
      caption2: 'Discover Amazing Places of the world',
      url: '#',
    },
    {
      src: shalimar,
      altText: 'Shalimar',
      caption1: 'Discover Amazing Places of the world',
      caption2: 'Discover Amazing Places of the world',
      url: '#',
    },
  ]

  const reviews = [
    {
      src: img,
      name: 'Thailand',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '4',
    },
    {
      src: img,
      name: 'Australia',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '2',
    },
    {
      src: img,
      name: 'South Africa',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '3',
    },
    {
      src: img,
      name: 'South Africa',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '3',
    },
    {
      src: img,
      name: 'South Africa',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '3',
    },
    {
      src: img,
      name: 'South Africa',
      desc: 'Proin nec efficitur nibh. Curabitur posuere aliquam arcu, et euismod felis sagittis sed',
      url: '/destination-details',
      rating: '3',
    },
  ]

  // const

  // const countryOptions = [{ value: "india", label: "India" }];

  const dispatch = useDispatch()
  const countries = useSelector((state) => state.dashboard.countries)

  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountry, setSelectedCountry] = useState('')

  const [stateOptions, setStateOptions] = useState([])
  const [selectedState, setSelectedState] = useState('')

  const [cityOptions, setCityOptions] = useState([])
  const [selectedCity, setSelectedCity] = useState('')

  const [attractions, setAttractions] = useState([])

  const [activeIndex, setActiveIndex] = useState(0)
  const [animating, setAnimating] = useState(false)

  const [loading, setLoading] = useState(false)

  const next = () => {
    if (animating) return
    const nextIndex = activeIndex === slides.length - 1 ? 0 : activeIndex + 1
    setActiveIndex(nextIndex)
  }

  const previous = () => {
    if (animating) return
    const nextIndex = activeIndex === 0 ? slides.length - 1 : activeIndex - 1
    setActiveIndex(nextIndex)
  }

  const goToIndex = (newIndex) => {
    if (animating) return
    setActiveIndex(newIndex)
  }

  const options = {
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const slider = (
    <OwlCarousel
      id="banner-home-slider"
      className="owl-carousel owl-theme"
      loop
      margin={0}
      items={1}
      nav
    >
      {slides.map((item) => {
        return (
          <div
            className="item slick-slider-home"
            key={item.src}
            style={{ backgroundImage: `url(${item.src})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                  {/* <div className="banner-text-home wow fadeInUp" data-wow-duration="2s">
                          <h1 className="title">{item.caption1}</h1>
                          <p className="slider-pararp">{item.caption2}</p>
                          <div className="button-common">
                              <a href={item.url} className="btn-1">Learn More</a>
                          </div>
                      </div> */}
                  <div className="button-common">
                    <a href="/enquiry" className="btn-1">
                      Enquire
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </OwlCarousel>
  )

  const countryChangeHandler = (value) => {
    setSelectedCountry(value)
    setStateOptions([])
    setSelectedState('')
    setCityOptions([])
    setSelectedCity('')
    getStates(value?.uuid)
    getCities(undefined, value?.uuid)
  }

  const stateChangeHandler = (value) => {
    setSelectedState(value)
    setCityOptions([])
    setSelectedCity('')
    getCities(value?.uuid, selectedCountry.uuid)
  }

  const getCountries = async () => {
    dispatch(listCountries())
  }

  const getStates = async (country) => {
    const resp = dispatch(listCountryStates({ country_uuid: country }))
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          const states = resp?.data
          if (states?.length) {
            const updatedStates = states.map((state) => {
              return { label: state.name, value: state.uuid, ...state }
            })
            setStateOptions(updatedStates)
          } else {
            setStateOptions([])
          }
        } else {
          setStateOptions([])
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      })
  }

  const getCities = async (state, country) => {
    const resp = dispatch(
      listCountryStateCities({
        country_uuid: country,
        state_uuid: state,
      })
    )
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          const cities = resp?.data
          if (cities?.length) {
            const updatedCities = cities.map((city) => {
              return { label: city.name, value: city.uuid, ...city }
            })
            setCityOptions(updatedCities)
          } else {
            setCityOptions([])
          }
        } else {
          setCityOptions([])
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
      })
  }

  const getAttractionFromLocation = async () => {
    setLoading(true)
    const resp = dispatch(
      listAttractionFromLocation({
        country_uuid: selectedCountry ? selectedCountry.uuid : undefined,
        state_uuid: selectedState ? selectedState.uuid : undefined,
        city_uuid: selectedCity ? selectedCity.uuid : undefined,
      })
    )
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp && resp.data) {
          setLoading(false)
          setAttractions(resp.data)
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setAttractions([])
        setLoading(false)
      })
  }

  const searchNowHandler = () => {
    getAttractionFromLocation()
  }

  const login = async () => {
    if (getToken()) {
      return
    }
    const resp = dispatch(
      loginSlice({ email: 'pqr@gmail.com', password: 'Abc@1234' })
    )
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          setContext(resp)
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        setLoading(false)
      })
  }

  useEffect(() => {
    if (countries?.length) {
      const updatedCountries = countries.map((country) => {
        return { label: country.name, value: country.uuid, ...country }
      })
      setCountryOptions(updatedCountries)
    }
  }, [countries])

  useEffect(() => {
    // login();
    getCountries()
    getAttractionFromLocation()
  }, [])

  return (
    <div className="landing-component">
      <div className="row">
        <div className="col-12 p-0 position-relative">{slider}</div>
      </div>

      <section id="tour-des">
        <div className="content-box">
          <h6>Travel Express Provide</h6>
          <h2>
            Select your <span>Destination</span>
          </h2>
          <p>
            Please select your dream destination from below, check out reviews for
            the best places to visit and more
          </p>
        </div>

        <BlockUi blocking={loading}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="booking-info">
                  <div className="select-box">
                    <Select
                      options={countryOptions}
                      placeholder="Select Country"
                      onChange={(value) => countryChangeHandler(value)}
                      value={selectedCountry}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="booking-info">
                  <div className="select-box">
                    <Select
                      options={stateOptions}
                      placeholder="Select State"
                      onChange={(value) => stateChangeHandler(value)}
                      value={selectedState}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6 col-12">
                <div className="booking-info">
                  <div className="select-box">
                    <Select
                      options={cityOptions}
                      placeholder="Select City"
                      onChange={(value) => setSelectedCity(value)}
                      value={selectedCity}
                    />
                  </div>
                </div>
              </div>
              {/* <div class="col-lg-3 col-md-6 col-sm-6 col-12">
              <div class="booking-info">
                <div class="select-box">
                  <Select
                    options={categoryOptions}
                    placeholder="Select Category"
                  />
                </div>
              </div>
            </div> */}
              <div className="col-lg-2 col-md-6 col-sm-6 col-12">
                <button
                  className="btn btn-2 pad"
                  disabled={!selectedCountry}
                  onClick={searchNowHandler}
                >
                  Search Now{' '}
                </button>
              </div>
            </div>
          </div>
          <div className="container  pt-5">
            {attractions && (
              <Slider
                id="slider-des"
                className="slider-des owl-carousel owl-theme"
                {...options}
                nav
              >
                {attractions.map((item) => {
                  return (
                    <div className="des-cov">
                      <div className="des-img">
                        <a href={`/attraction-details/${item?._id}`}>
                          <img
                            src={item.image?.length && item.image[0]}
                            alt={item?.name}
                          />
                        </a>
                      </div>
                      <div className="des-para position-relative">
                        <div className="dayt">
                          <h6>
                            <a href={`/attraction-details/${item?._id}`}>
                              {item?.name}
                            </a>
                          </h6>
                        </div>
                        <div className="real-dat-para">
                          <p>{item?.description?.slice(0, 100) + '...'}</p>
                        </div>
                        <div className="d-flex flex-wrap">
                          {item?.category?.map((catObj) => {
                            return (
                              <div className="p-1 px-2 mb-2 category-chip">
                                {catObj}
                              </div>
                            )
                          })}
                        </div>
                        <div className="des-button-icon mt-2">
                          <div className="das-into-btn">
                            <a
                              href={`/attraction-details/${item?._id}`}
                              className="btn-3"
                            >
                              Read More
                            </a>
                          </div>
                          <div className="start-icon-des">
                            <ReactStars
                              count={5}
                              size={24}
                              isHalf={true}
                              emptyIcon={<i className="far fa-star"></i>}
                              halfIcon={<i className="fa fa-star-half-alt"></i>}
                              fullIcon={<i className="fa fa-star"></i>}
                              activeColor="#ffd700"
                              edit={false}
                              value={item.avg_review}
                              className=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </Slider>
            )}
          </div>
        </BlockUi>
      </section>

      <section id="summery">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="all-space-to wow zoomIn" data-wow-duration="1.5s">
                <div className="summery-cover">
                  <ul className="all-spance text-white">
                    <li>
                      <p>The cheapest market rates for your travel needs</p>
                    </li>
                    <li>
                      <p>
                        Build your own custom itinerary to visit the places you
                        really want to see
                      </p>
                    </li>
                    <li>
                      <p>Experience a truly personalized travel experience</p>
                    </li>
                    <li>
                      <p>Personalised 24 hours on tour assistance</p>
                    </li>
                    <li>
                      <p>Complimentary night stay on your first booking</p>
                    </li>
                  </ul>
                </div>
                {/* <div class="all-spance">
                  <span>4days / 5nights</span>
                </div> */}
                {/* <div class="all-span-btn-com">
                  <a href="#" class="btn btn-2 mar-top">
                    View Details
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
export default LandingComponent
