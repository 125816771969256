import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "./Enquiry.scss";
import { getBustySlots } from "../../shared/redux/slices/enquirySlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { busySlots } from "../../shared/redux/apis/enquiryAPI";

// creating functional component ans getting props from app.js and destucturing them
const StepThree = ({
  nextStep,
  prevStep,
  stepThreeValues,
  setStepThreeValues,
  addEnquiry,
}) => {
  //creating error state for validation
  const dispatch = useDispatch();
  const [calVal, setCalValue] = useState(new Date());

  const [selectedSlot, setSelectedSlot] = useState(null);
  const [busySlotsList, setBusySlotsList] = useState([]);

  const SLOTS_LIST = [
    { label: "9:00 AM", value: "9:00 AM" },
    { label: "9:30 AM", value: "9:30 AM" },
    { label: "10:00 AM", value: "10:00 AM" },
    { label: "10:30 AM", value: "10:30 AM" },
    { label: "11:00 AM", value: "11:00 AM" },
    { label: "11:30 AM", value: "11:30 AM" },
    { label: "12:00 PM", value: "12:00 PM" },
    { label: "12:30 PM", value: "12:30 PM" },
    { label: "1:00 PM", value: "1:00 PM" },
    { label: "1:30 PM", value: "1:30 PM" },
    { label: "2:00 PM", value: "2:00 PM" },
    { label: "2:30 PM", value: "2:30 PM" },
    { label: "3:00 PM", value: "3:00 PM" },
    { label: "3:30 PM", value: "3:30 PM" },
    { label: "4:00 PM", value: "4:00 PM" },
    { label: "4:30 PM", value: "4:30 PM" },
    { label: "5:00 PM", value: "5:00 PM" },
    { label: "5:30 PM", value: "5:30 PM" },
    { label: "6:00 PM", value: "6:00 PM" },
    { label: "6:30 PM", value: "6:30 PM" },
    { label: "7:00 PM", value: "7:00 PM" },
    { label: "7:30 PM", value: "7:30 PM" },
    { label: "8:00 PM", value: "8:00 PM" },
    { label: "8:30 PM", value: "8:30 PM" },
    { label: "9:00 PM", value: "9:00 PM" },
  ];

  const [slots, setSlots] = useState([]);

  // after form submit validating the form data using validator
  const submitFormData = (e) => {
    nextStep();
  };

  const listBusySlots = async () => {
    let startDate = moment(calVal)
      .hour(9)
      .minute(0)
      .second(0)
      .format("DD-MMM-YYYY, hh:mm A");
    let endDate = moment(calVal)
      .hour(21)
      .minute(0)
      .second(0)
      .format("DD-MMM-YYYY, hh:mm A");

    // startDate = moment(startDate).toDate();
    // endDate = moment(endDate).toDate();
    const resp = dispatch(
      getBustySlots({
        start_time: new Date(startDate).toISOString(),
        end_time: new Date(endDate).toISOString(),
      })
    );
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp) {
          setBusySlotsList(resp);
        } else {
          setBusySlotsList([]);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log("Error: ", rejectedValueOrSerializedError);
      });
  };

  const generateSlots = (value) => {
    let startDate = moment(value)
      .hour(9)
      .minute(0)
      .second(0)
      .format("DD-MMM-YYYY, hh:mm A");
    let endDate = moment(value)
      .hour(21)
      .minute(0)
      .second(0)
      .format("DD-MMM-YYYY, hh:mm A");

    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();

    let slotList = [];

    while (startDate <= endDate) {
      let slot = moment(startDate).format("hh:mm A");
      slotList.push({ label: slot, value: slot, date: new Date(startDate) });
      startDate.setMinutes(startDate.getMinutes() + 30);
    }
    setSlots(slotList);
  };

  const checkSlotAvailability = (slot) => {
    let currentDate = new Date();
    if (slot.date < currentDate) {
      return "time-slot-disabled";
    } else if (busySlotsList.length > 0) {
      let slotFound = busySlotsList.find((slotObj) => {
        let date = new Date(slotObj.start);
        return date.getTime() === slot.date.getTime();
      });
      return slotFound ? "time-slot-busy" : "time-slot-available";
    } else {
      return "time-slot-available";
    }
  };

  const setCalendarDate = (val) => {
    setCalValue(val);
    setSelectedSlot(null);
    generateSlots(val);
  };

  const handleSubmit = () => {
    let endDate = new Date(selectedSlot.date);
    endDate.setMinutes(selectedSlot.date.getMinutes() + 30);
    setStepThreeValues({
      start_time: selectedSlot.date.toISOString(),
      end_time: endDate.toISOString(),
    });
  };

  useEffect(() => {
    listBusySlots();
  }, [calVal]);
  useEffect(() => {
    generateSlots(calVal);
  }, [busySlotsList]);

  return (
    <div className="common_author_boxed step-form">
      <div className="common_author_heading">
        <h3 className="text-start">Talk To Our Experts</h3>
      </div>
      <form id="profile_form_area" className="talk-to-experts">
        <div className="row container">
          <div className="col-12">
            <Calendar
              onChange={(val) => setCalendarDate(val)}
              value={calVal}
              className="w-100"
            />
          </div>
          <hr className="my-4" />
          <div className="col-12">
            <div className="slot-container">
              {slots.map((slot, index) => {
                return (
                  <div
                    className={`time-slot ${
                      selectedSlot?.value === slot?.value
                        ? "time-slot-booked"
                        : checkSlotAvailability(slot)
                    }`}
                    key={`slot-${index}`}
                    onClick={() => {
                      return checkSlotAvailability(slot) ===
                        "time-slot-available"
                        ? setSelectedSlot(slot)
                        : null;
                    }}
                  >
                    {slot.label}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </form>

      <div
        style={{ display: "flex", justifyContent: "space-around" }}
        className="mt-5"
      >
        <button className="btn btn-primary" onClick={prevStep}>
          Previous
        </button>

        <button
          className="btn btn-2"
          type="submit"
          onClick={handleSubmit}
          disabled={!selectedSlot}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default StepThree;
