import TopHeader from './TopHeader'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import LandingComponent from '../landing/LandingComponent'
import Footer from './Footer'
import Header from './Header'
import LoginComponent from '../login/LoginComponent'
import MyProfile from '../my-account/my-profile/MyProfile'
import MyTransactions from '../my-account/my-transactions/MyTransactions'
import ForgotPassword from '../forgot-password/ForgotPassword'
import ActivateAccount from '../activate-account/ActivateAccount'
import SubmitReview from '../my-account/submit-review/SubmitReview'
import ReviewDetails from '../review-details/ReviewDetails'
import Enquiry from '../enquiry/Enquiry'
import Review from '../submit-review/Review'
import DestinationDetails from '../destination-details/DestinationDetails'
import MyEnquiries from '../my-account/my-enquiries/MyEnquiries'
import { ToastContainer } from 'react-toastify'
import TestimonialComponent from '../testimonials/TestimonialComponent'
import SubmitTestimonial from '../testimonials/SubmitTestimonial'

const ContainerComponent = (props) => {
  return (
    <div className="">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <BrowserRouter>
        <Header />
        <div>
          <Switch>
            <Route exact path="/">
              <LandingComponent />
            </Route>
            <Route exact path="/login">
              <LoginComponent />
            </Route>
            <Route exact path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route exact path="/activate-account">
              <ActivateAccount />
            </Route>
            <Route exact path="/my-profile">
              <MyProfile />
            </Route>
            <Route exact path="/my-transactions">
              <MyTransactions />
            </Route>
            <Route exact path="/my-enquiries">
              <MyEnquiries />
            </Route>
            <Route exact path="/submit-review">
              <SubmitReview />
            </Route>
            <Route exact path="/reviews/:attractionId">
              <ReviewDetails />
            </Route>
            <Route exact path="/attraction-details/:id">
              <DestinationDetails />
            </Route>
            <Route exact path="/enquiry">
              <Enquiry />
            </Route>
            <Route exact path="/review">
              <Review />
            </Route>
            <Route exact path="/review/:attractionId">
              <Review />
            </Route>
            <Route exact path="/testimonials">
              <TestimonialComponent />
            </Route>
            <Route exact path="/submit-testimonials">
              <SubmitTestimonial />
            </Route>
          </Switch>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  )
}
export default ContainerComponent
