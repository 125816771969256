import React, { useState, useEffect } from "react";
import Stepper from "react-stepper-horizontal";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";

import "react-datepicker/dist/react-datepicker.css";
import "./Enquiry.scss";
import StepFour from "./StepFour";
import { useDispatch } from "react-redux";
import { createEnquirySlice } from "../../shared/redux/slices/enquirySlice";
import moment from "moment/moment";
import BlockUi from "react-block-ui";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const Enquiry = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [stepOneValues, setStepOneValues] = useState(null);
  const [stepTwoValues, setStepTwoValues] = useState(null);
  const [stepThreeValues, setStepThreeValues] = useState(null);
  const [loading, setLoading] = useState(false);

  // function for going to next step by increasing step state by 1
  const nextStep = () => {
    setStep(step + 1);
    window.scrollTo(0, 0);
  };

  // function for going to previous step by decreasing step state by 1
  const prevStep = () => {
    setStep(step - 1);
  };

  const addEnquiry = async () => {
    setLoading(true);
    let params = {
      destinations: stepOneValues?.destinations,
      occasions: stepOneValues?.occasions,
      number_of_adults: stepOneValues?.number_of_adults,
      number_of_kids: stepOneValues?.number_of_kids,
      number_of_senior_citizens: stepOneValues?.number_of_senior_citizens,
      meal_plan: stepOneValues?.meal_plan,
      coupon_code: stepOneValues?.coupon_code,
      from_date: moment(stepOneValues?.from_date).format("YYYY-MM-DD"),
      to_date: moment(stepOneValues?.to_date).format("YYYY-MM-DD"),
      traveling_with: stepOneValues?.traveling_with?.value,
      any_special_request: stepOneValues?.any_special_request,
      first_name: stepTwoValues?.first_name,
      last_name: stepTwoValues?.last_name,
      phone_number: stepTwoValues?.phone_number,
      email: stepTwoValues.email,
      scheduler: {
        start_time: stepThreeValues.start_time,
        end_time: stepThreeValues.end_time,
        attendees: `${stepTwoValues.email},enquiry@trip-mitra.com`,
        summary: "Trip-mitra scheduler sync-up",
      },
    };

    const resp = dispatch(createEnquirySlice(params));
    resp
      .unwrap()
      .then((resp) => {
        // handle result here
        if (resp?.data) {
          toast.success(
            "Thanks for connecting with submitting the enquiry. We will get back to you soon"
          );
          setLoading(false);
          history.push("/");
        } else {
          setLoading(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        console.log("Enquiry created Error: ", rejectedValueOrSerializedError);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (stepThreeValues) {
      addEnquiry();
    }
  }, [stepThreeValues]);

  return (
    <div className="container pt-5 pb-5">
      <BlockUi blocking={loading}>
        <div className="row">
          <div className="col-lg-12">
            <div className="d-steps">
              <Stepper
                titleFontSize={18}
                steps={[
                  { title: "Destination Details" },
                  { title: "User Details" },
                  { title: "Talk To Our Experts" },
                  // { title: "Summary" },
                ]}
                activeStep={step - 1}
              />
            </div>
            {(() => {
              switch (step) {
                case 1:
                  return (
                    <div>
                      <StepOne
                        nextStep={nextStep}
                        setStepOneValues={setStepOneValues}
                        stepOneValues={stepOneValues}
                      />
                    </div>
                  );
                case 2:
                  return (
                    <div>
                      <StepTwo
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setStepTwoValues={setStepTwoValues}
                        stepTwoValues={stepTwoValues}
                      />
                    </div>
                  );
                case 3:
                  return (
                    <div>
                      <StepThree
                        nextStep={nextStep}
                        prevStep={prevStep}
                        setStepThreeValues={setStepThreeValues}
                        stepThreeValues={stepThreeValues}
                        addEnquiry={addEnquiry}
                      />
                    </div>
                  );
                // case 4:
                //   return (
                //     <div>
                //       <StepFour
                //         nextStep={nextStep}
                //         prevStep={prevStep}
                //         stepOneValues={stepOneValues}
                //         stepTwoValues={stepTwoValues}
                //       />
                //     </div>
                //   );
                // default case to show nothing
                default:
                  return <div></div>;
              }
            })()}
          </div>
        </div>
      </BlockUi>
    </div>
  );
};
export default Enquiry;
