import TopHeader from './TopHeader'
import logo from '../../assets/travelMitraLogo.svg'
import { getFName, getToken, removeContext } from '../../shared/SessionHelper'
import Popup from 'reactjs-popup'
import './Header.scss'
import { useHistory } from 'react-router-dom'

const Header = (props) => {
  const history = useHistory()
  const logout = () => {
    removeContext()
    window.location.href = '/'
  }

  const onMenuClick = (url) => {
    history.push(url)
  }

  return (
    <>
      {/* <TopHeader /> */}
      <header className="site-header header-style-one header">
        <div className="site-navigation style-one">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-4 navbar navbar-expand-lg navigation-area">
                <div className="site-branding">
                  <a className="site-logo" href="/">
                    <img src={logo} alt="Site-logo" width={'230px'} />
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div className="mainmenu-area">
                  {/* <nav className="menu">
                        <ul id="nav">
                          <li>
                            <a className="active" href="#">Home</a>
                          </li>
                          <li>
                            <a href="#">Trip Package</a>
                          </li>
                          <li>
                            <a href="#">Service</a>
                          </li>
                          <li>
                            <a href="#">Blog</a>
                          </li>
                          <li>
                            <a href="#">Contact</a>
                          </li>
                        </ul>
                      </nav> */}
                  <div className="row">
                    <div className="col-12">
                      <div className="main-flex-top">
                        <div className="email sel d-flex justify-content-end">
                          <div className="main-email-text d-flex">
                            <a href="mailto:infp@a2z.com" className="d-flex">
                              <i className="fas fa-envelope"></i>
                              <p className="fs-6">enquiry@trip-mitra.com</p>
                            </a>
                          </div>
                          <div className="main-loc-text right-separator">
                            <a
                              target="_blank"
                              href="tel:9128374650"
                              className="d-flex"
                            >
                              <i className="fas fa-phone"></i>
                              <p className="me-3 fs-6">9822452300</p>
                            </a>
                          </div>
                          <div className="main-loc-text sign-in right-separator me-3">
                            <a
                              href="/testimonials"
                              className="d-flex me-3 align-items-center"
                            >
                              <i className="fa fa-comments testimonial-icon"></i>
                              <span>Our Testimonials</span>
                            </a>
                          </div>
                          <div className="login-area">
                            <div className="main-loc-text sign-in">
                              <i className="far fa-user-circle"></i>
                              {getToken() ? (
                                // <a href="" onClick={logout}>
                                //   Sign Out
                                // </a>
                                <Popup
                                  trigger={
                                    <span>
                                      {getFName()}
                                      <i className="fa fa-caret-down"></i>
                                    </span>
                                  }
                                  position="bottom center"
                                  on={['hover', 'focus']}
                                  className="profile-menu"
                                >
                                  <div>
                                    <ul>
                                      <li
                                        className="menu-item"
                                        onClick={() => onMenuClick('/my-profile')}
                                      >
                                        My Profile
                                      </li>
                                      <li
                                        className="menu-item separator"
                                        onClick={() => onMenuClick('/my-enquiries')}
                                      >
                                        My Enquiries
                                      </li>
                                      <li
                                        className="menu-item separator"
                                        onClick={() =>
                                          onMenuClick('/my-transactions')
                                        }
                                      >
                                        My Transactions
                                      </li>
                                      <li
                                        className="menu-item separator"
                                        onClick={logout}
                                      >
                                        Sign Out
                                      </li>
                                    </ul>
                                  </div>
                                </Popup>
                              ) : (
                                <a href="/login">Sign In</a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mobile-menu-area">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="mobile-menu">
                      <a className="mobile-logo" href="/">
                        <img src={logo} alt="logo" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
      </header>
    </>
  )
}
export default Header
