import { axiosPublic, axiosPublicWithPort } from "../../ApiHandler";
import { getToken } from "../../SessionHelper";

export const createEnquiry = (params) => {
  // return axiosPublic.post(`/api/v1/enquiry/create/`, params, {
  //   headers: { "Content-Type": "multipart/form-data" },
  // });

  return axiosPublic({
    method: "post",
    url: `/api/v1/enquiry/create/`,
    data: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${getToken()}`,
    },
  });
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const busySlots = (params) => {
  // return axiosPublic.get(`/api/v1/enquiry/scheduler/busy_slots`, params);
  return axiosPublic({
    method: "post",
    url: `/api/v1/enquiry/scheduler/busy_slots/`,
    data: params,
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${getToken()}`,
    },
  });
};

/**
 * Provides the list of all assets stats.
 * @returns -- list of all assets stats.
 */
export const getAllEnquiresByUser = (params) => {
  return axiosPublic({
    method: "get",
    url: `/api/v1/enquiry/get/user/${params.userId}`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `token ${getToken()}`,
    },
  });
};
